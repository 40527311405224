import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
const getProductCouponsByShop = async ({
  locale,
  sort_key,
  filter_type,
  formatData,
}) => {
  const url = `/api/multipass/shopifyservices/coupons/by_shop`
  const result = await fetcher({
    url,
    locale,
    method: 'GET',
    body: {
      sort_key: sort_key || 'percentage',
      filter_type: filter_type || '',
      shopify_domain: I18N_STORE_DOMAIN[locale],
    },
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductCouponsByShop
