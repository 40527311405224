import { useState, useEffect, useMemo, useCallback } from 'react'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import Head from 'next/head'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import useCustomer from '@shopify/customer/use-customer'
import { admitadRetag } from '@lib/utils/thirdparty'
import { getRandomValueFromArray, getURLParameter } from '@lib/utils/tools'
import {
  ProfileContext,
  ProfileProvider,
} from 'templates/Pages/Credit/ProfileContext'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import useAllDiscountedProducts from '@lib/hooks/useAllDiscountedProducts'
import { getComponentData } from '@components/helper'
import s from './HomeCharger.module.css'
import Cookies from 'js-cookie'

import { Text } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/outline'
import HomeMarquee from './components/HomeMarquee/HomeMarquee'
import HomeBannerV2 from './components/HomeBannerV2/HomeBannerV2'
const Component = dynamic(() => import('@components/Component'))
const RecallNavigation = dynamic(() => import('./RecallNavigation'))
const Featured = dynamic(() => import('./Featured'))
const Convenient = dynamic(() => import('./Convenient'))
const GiftingList = dynamic(() => import('./GiftingList'))
const Carousel = dynamic(() => import('@components/home/Carousel'))
const HomeProductList = dynamic(() =>
  import('@components/home/HomeProductList')
)
const SearchFans = dynamic(() => import('@components/home/SearchFans'))
const PeaceOfMind = dynamic(() => import('@components/home/PeaceOfMind'))
const AdvantagePowerHouse = dynamic(() =>
  import('@components/home/AdvantagePowerHouse')
)
const Categorylist = dynamic(() => import('@components/home/Categorylist'))
const ProductSeries = dynamic(() => import('../Solix/components/ProductSeries'))
const SeriesList = dynamic(() => import('@components/home/SeriesList'))
const Quiz = dynamic(() => import('@components/home/Quiz'))
const Advantage = dynamic(() => import('@components/home/Advantage'))
const BrandIntroduction = dynamic(() =>
  import('@components/home/BrandIntroduction')
)
const Media = dynamic(() => import('@components/home/Media'))
const Banner = dynamic(() => import('@components/banner/HomeBanner'))
const SwiperBanner = dynamic(() => import('@components/banner/SwiperBanner'))
const HotSpots = dynamic(() => import('@components/home/HotSpots'))
const HomeDealsList = dynamic(() =>
  import('@components/home/HomeDealsList/HomeDealsList')
)
const HomeActivityList = dynamic(() =>
  import('templates/Pages/Acomponentsfort/HomeActivityList/HomeActivityList')
)
const HomeCollectionsList = dynamic(() =>
  import(
    'templates/Pages/Acomponentsfort/HomeCollectionsList/HomeCollectionsList'
  )
)
const HomeCollectionsImgs = dynamic(() =>
  import('./components/HomeCollectionsImgs/HomeCollectionsImgs')
)
const HomeBrandVideo = dynamic(() =>
  import('./components/HomeBrandVideo/HomeBrandVideo')
)
const HomeProductsList = dynamic(() =>
  import('./components/HomeProducts/HomeProductsList')
)
const HomeFlagship = dynamic(() =>
  import('./components/HomeProducts/HomeFlagship')
)
const HomeRecommends = dynamic(() =>
  import('templates/Pages/Acomponentsfort/HomeRecommends/HomeRecommends')
)
const HomeBrandEquity = dynamic(() =>
  import('./components/HomeBrandEquity/HomeBrandEquity')
)
const HomeCharger = ({
  page,
  relatedProducts,
  metafields,
  pageType,
  categories = [],
}) => {
  const { data: customer } = useCustomer()
  const router = useRouter()
  const { locale } = router
  const {
    component_order,
    ppsSubScribe = {},
    chargerSubScribe = {},
    ppsFooter = {},
    chargerFooter = {},
    navSetting = {},
    shop_collections = {},
    menuCharger = {},
    menuPps = {},
    criteoSettings = {},
    thirdparty = {},
    smoothScroll,
  } = metafields
  const [versionB, setVersionB] = useState(false)
  const [youTubeId, setYouTubeId] = useState('')
  const shopData = {
    shopCommon: metafields.shopCommon,
    pageCommon: metafields.pageCommon,
  }
  const { menuTheme, setMenuTheme } = useUI()
  const [precisoShow, setPrecisoShow] = useState(false)
  const [abtest, setAbtest] = useState({})
  const componentsDom = useMemo(() => {
    if (component_order) {
      return component_order.map((dataKey, i) => {
        const { key: componentKey, data } = getComponentData(
          metafields,
          dataKey
        )
        if (i === 0 && data?.theme) {
          setMenuTheme(data?.theme?.toLowerCase())
        }
        return {
          componentKey,
          data,
          dataKey,
        }
      })
    }
    return []
  }, [])

  // 折扣产品
  let allDiscountProduct = useAllDiscountedProducts()

  const mapFieldToCoupons = useCallback(
    (discountProduct, filterArr, field = 'handle') => {
      const _coupons = discountProduct.reduce((coupons, item) => {
        if (filterArr.includes(item?.[field])) {
          if (coupons[item?.[field]]?.length) {
            coupons[item?.[field]].push(item)
          } else {
            coupons[item?.[field]] = [item]
          }
        }
        return coupons
      }, {})
      return _coupons || {}
    },
    []
  )

  const [isMobile, setIsMobile] = useState(true)
  const resizeWindow = () => {
    ScrollTrigger.refresh()
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth < 768) return setIsMobile(true)
    setIsMobile(false)
  }
  useEffect(() => {
    resizeWindow()
    window?.addEventListener('resize', resizeWindow)
    return () => {
      window?.removeEventListener('resize', resizeWindow)
    }
  }, [])

  useEffect(() => {
    if (locale == 'fr' || locale == 'eu-en' || locale == 'ca') {
      setPrecisoShow(true)
    } else if (locale == 'us' || locale == 'eu-de' || locale == 'uk') {
      if (Cookies.get('ref_ads')?.indexOf('utm_source=preciso') >= 0) {
        setPrecisoShow(true)
        return false
      }
      if (Math.random() < 0.5) {
        setPrecisoShow(true)
      }
    }
  }, [])

  const getProduct = (handle) => {
    return relatedProducts?.find(
      (p) => handle?.toLowerCase() === p?.result?.handle?.toLowerCase()
    )?.result
  }

  useEffect(() => {
    router.prefetch(`${locale === 'us' ? '' : '/' + locale}/ankerpower`)
  }, [])

  useEffect(() => {
    // criteo 事件移动到GTM
    // var deviceType = /iPad/.test(navigator.userAgent)
    //   ? 't'
    //   : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
    //       navigator.userAgent
    //     )
    //   ? 'm'
    //   : 'd'
    // window.criteo_q = window?.criteo_q || []
    // window?.criteo_q.push(
    //   {
    //     event: 'setAccount',
    //     account: {
    //       an: criteoSettings?.an,
    //       cn: criteoSettings?.cn,
    //       ln: criteoSettings?.ln,
    //     },
    //   },
    //   {
    //     event: 'setHashedEmail',
    //     email: customer?.email ? md5Func(customer?.email) : '',
    //   },
    //   {
    //     event: 'setSiteType',
    //     type: deviceType,
    //   },
    //   { event: 'viewHome' }
    // )
  }, [])

  useEffect(() => {
    // AB测试代码
    const cov = getURLParameter('cov', window.location.href)
    if (cov === 'b') {
      setVersionB(true)
    } else {
      setVersionB(false)
    }
    if (thirdparty?.admitad_code) {
      admitadRetag({ code: '9ce8884c11', level: 0 })
    }
  }, [])

  useEffect(() => {
    const testList = componentsDom.reduce((ac, cu) => {
      const { dataKey, componentKey } = cu
      if (dataKey.includes('abtest')) {
        if (ac?.[componentKey]) {
          ac[componentKey].push(dataKey)
        } else {
          ac[componentKey] = [dataKey]
        }
      }
      return ac
    }, {})

    const hostUrl = window.location.host.split('.')
    const domain =
      hostUrl.length > 2
        ? `.${hostUrl[1]}.${hostUrl[2]}`
        : window.location.hostname

    const ab = {}
    Object.keys(testList).forEach((key) => {
      const coo = Cookies.get(`abtest_${key}`)
      if (coo) {
        ab[key] = coo
      } else {
        const current = getRandomValueFromArray(testList[key])
        ab[key] = current
        Cookies.set(`abtest_${key}`, current, {
          domain,
          expires: 1,
        })
      }
    })
    setAbtest(ab)
  }, [])

  return (
    <>
      {precisoShow && (
        <Script
          type={'text/javascript'}
          id="glb"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.data_4259 = [];
            window.data_4259.push({
            'gdpr': 0,
            'gdpr_consent': 'GDPR_CONSENT_874',
            'gdpr_pd': 0,
            'us_privacy': '',
            'PageType': 'home'
            });
            (function(d) {
              var s = d.createElement('script'); s.async = true;s.id='DLFNPMMP1';s.type='text/javascript';
              s.src ='https://cdn.preciso.net/aud/clientjs/ptag.js?4259';
              var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
            }(document));
          `,
          }}
        />
      )}

      <Script
        id="youtube-iframe-api"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `var tag = document.createElement('script'); tag.src
                ='https://www.youtube.com/iframe_api'; var firstScriptTag =
                document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);`,
        }}
      />

      {thirdparty?.admitad_code && (
        <>
          <Head>
            <meta
              name="google-site-verification"
              content="zsEp4z60m1ersWmAH6P7rHxbtetc1E9H-z_YmadWv1w"
            />
          </Head>
        </>
      )}

      <style>
        {`
          .fit {
            background: #F0F1F2;
          }
        `}
      </style>

      <div className={s.root}>
        <div className={s.main}>
          {componentsDom.map(({ dataKey, componentKey, data }, i) => {
            if (
              dataKey.includes('abtest') &&
              abtest?.[componentKey] !== dataKey
            ) {
              return ''
            }
            switch (componentKey) {
              case 'giftingList':
                return (
                  <GiftingList data={data} id={dataKey} dataKey={dataKey} />
                )
              case 'homeBanner':
                return (
                  <Banner
                    gtmRef={`masterBanner${i + 1}`}
                    product={getProduct(data?.handle)}
                    lang={data}
                    id={dataKey}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'swiperBanner':
                return (
                  <SwiperBanner
                    gtmRef={`masterBanner${i + 1}`}
                    relatedProducts={relatedProducts}
                    lang={data}
                    id={dataKey}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'ProductSeries':
                return (
                  <ProductSeries
                    data={data}
                    pageType={pageType}
                    dataKey={dataKey + i}
                  ></ProductSeries>
                )
              case 'featured':
                return (
                  <Featured
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    metafields={metafields}
                    pageHandle={page?.handle}
                  ></Featured>
                )
              case 'convenient':
                return (
                  <Convenient
                    data={data}
                    locale={locale}
                    dataKey={dataKey}
                    pageType={pageType}
                  ></Convenient>
                )
              case 'peaceOfMind':
                return (
                  <PeaceOfMind
                    data={data}
                    id={dataKey}
                    key={dataKey}
                    pageType={pageType}
                  />
                )
              case 'advantagePowerHouse':
                return (
                  <AdvantagePowerHouse
                    data={data}
                    id={dataKey}
                    key={dataKey}
                    pageType={pageType}
                  />
                )
              case 'hotSpotsList':
                return (
                  <HotSpots
                    data={data}
                    id={dataKey}
                    indexList={i}
                    relatedProducts={relatedProducts}
                    pageType={pageType}
                    metafields={metafields}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'recallNavigation':
                return (
                  <RecallNavigation
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'homeProductList':
                return (
                  <HomeProductList
                    relatedProducts={relatedProducts}
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                    metafields={metafields}
                  />
                )
              case 'seriesList':
                return (
                  <SeriesList
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'quizVideo':
                return (
                  <Quiz
                    indexList={i}
                    data={data}
                    id={dataKey}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'categoryList':
                return (
                  <Categorylist
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'advantage':
                return (
                  <Advantage
                    pageType={pageType}
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'searchFans':
                return (
                  <SearchFans
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'brandIntroduction':
                return (
                  <BrandIntroduction
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'homeMedia':
                return (
                  <Media
                    indexList={i}
                    data={data}
                    id={dataKey}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'celebrityCarousel':
                return (
                  <Carousel
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'homeBannerV2':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeBannerV2
                      gtmRef={`homepage_masterBanner_${i + 1}`}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      shopData={shopData}
                    />
                  </PageSection>
                )
              case 'homeMarquee':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeMarquee
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      isMobile={isMobile}
                    />
                  </PageSection>
                )
              case 'homeDealsList':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeDealsList
                      gtmRef={data?.ref}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      setYouTubeId={setYouTubeId}
                    />
                  </PageSection>
                )
              case 'homeActivityList':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeActivityList
                      gtmRef="homepage_2thbanner"
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                    />
                  </PageSection>
                )
              case 'homeCollectionsList':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeCollectionsList
                      gtmRef="homepage_3thbanner"
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                    />
                  </PageSection>
                )
              case 'homeCollectionsImgs':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeCollectionsImgs
                      gtmRef={`homepage_6thbanner`}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      versionB={versionB}
                    />
                  </PageSection>
                )
              case 'homeBrandVideo':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeBrandVideo
                      gtmRef={`homepage_14thbanner`}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      isMobile={isMobile}
                      setYouTubeId={setYouTubeId}
                    />
                  </PageSection>
                )
              case 'homeProductsList':
                return (
                  <PageSection
                    id={dataKey}
                    key={dataKey}
                    // data={data} //适配动画 padding值要放在组件里面
                    isSticky={true}
                  >
                    <ProfileProvider>
                      <ProfileContext.Consumer>
                        {({ profile, creditInfo }) => {
                          return (
                            <HomeProductsList
                              gtmRef="homepage_11thbanner"
                              profile={profile}
                              creditInfo={creditInfo}
                              pageType={pageType}
                              data={data}
                              id={dataKey}
                              pageHandle={page?.handle}
                              shopData={shopData}
                              metafields={metafields}
                              dealsProducts={allDiscountProduct}
                              mapFieldToCoupons={mapFieldToCoupons}
                            />
                          )
                        }}
                      </ProfileContext.Consumer>
                    </ProfileProvider>
                  </PageSection>
                )
              case 'homeFlagship':
                return (
                  <PageSection
                    id={dataKey}
                    key={dataKey}
                    // data={data} //适配动画 padding值要放在组件里面
                    isSticky={true}
                  >
                    <HomeFlagship
                      gtmRef="homepage_4thbanner"
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      shopData={shopData}
                      dealsProducts={allDiscountProduct}
                      mapFieldToCoupons={mapFieldToCoupons}
                    />
                  </PageSection>
                )
              case 'homeRecommends':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <HomeRecommends
                      gtmRef={`homepage_7thbanner`}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                      shopData={shopData}
                      dealsProducts={allDiscountProduct}
                      mapFieldToCoupons={mapFieldToCoupons}
                      versionB={versionB}
                    />
                  </PageSection>
                )
              case 'homeBrandEquity':
                return (
                  <PageSection
                    id={dataKey}
                    key={dataKey}
                    data={data}
                    className="z-[2]"
                  >
                    <HomeBrandEquity
                      gtmRef={`homepage_8thbanner`}
                      data={data}
                      id={dataKey}
                      pageHandle={page?.handle}
                    />
                  </PageSection>
                )
              case 'homeNote':
                return (
                  <PageSection id={dataKey} key={dataKey} data={data}>
                    <div className="layer">
                      <div className="content border-b border-[#fff] border-opacity-20 pb-[40px] xl:pb-[32px]">
                        <Text
                          className="text-[16px] font-[500] leading-[1.6] text-[#999] xl:text-[12px]"
                          html={data?.note}
                        />
                      </div>
                    </div>
                  </PageSection>
                )
              default:
                return (
                  <Component
                    key={dataKey}
                    dataKey={dataKey}
                    pagehandle={page?.handle}
                    metafields={metafields}
                  />
                )
            }
          })}
        </div>
      </div>

      {youTubeId && (
        <YouTubeVideo youTubeId={youTubeId} setYouTubeId={setYouTubeId} />
      )}
    </>
  )
}

export default HomeCharger

const PageSection = ({ id, className, data, isSticky = false, ...rest }) => {
  return (
    <section
      id={id}
      className={cn(
        data?.styles?.paddingTop && s.paddingTop,
        data?.styles?.paddingBottom && s.paddingBottom,
        data?.styles?.paddingTopMin && s.paddingTopMin,
        data?.styles?.paddingBottomMin && s.paddingBottomMin,
        data?.styles?.paddingTopMob && s.paddingTopMob,
        data?.styles?.marginBottom && 'mb-[8px]',
        'PageSection relative leading-[1.2] ' + className || '',
        isSticky ? 'overflow-unset' : 'overflow-hidden',
        s.PageSection
      )}
      style={{
        background: data?.styles?.backgroundColor || '#F0F1F2',
        zIndex: data?.styles?.zIndex,
      }}
      {...rest}
    />
  )
}

const YouTubeVideo = ({ youTubeId, setYouTubeId }) => {
  console.log('youTubeId', youTubeId)
  let playerEvent
  const onPlayerReady = (event) => {
    playerEvent = event.target
    event.target.playVideo()
  }
  const onYouTubeIframeAPIReady = () => {
    if (window?.YT && window?.YT?.Player) {
      let players = new YT.Player('x1Player', {
        height: '100%',
        width: '100%',
        videoId: youTubeId || pageCommon?.liveYoutubeId,
        events: {
          onReady: onPlayerReady,
        },
      })
    }
  }
  useEffect(() => {
    onYouTubeIframeAPIReady()
  }, [])
  return (
    <>
      <div className={s.dialogWrap}>
        <XMarkIcon
          className={s.closeWrap}
          onClick={() => {
            setYouTubeId('')
          }}
        />
        <div id="x1Player"></div>
      </div>
    </>
  )
}
