import { isBoa } from '@components/helper'
import getProductCouponsByShop from '@lib/apis/get-product-coupons-by-shop'

import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

const useAllDiscountedProducts = (filterBoa = true) => {
  const [products, setProducts] = useState([])
  const { locale } = useRouter()

  const couponCallback = useCallback(async () => {
    const result = await getProductCouponsByShop({
      locale,
      sort_key: 'percentage',
    })

    // 排除 boa 产品
    const filteredProducts = result?.length
      ? result?.filter((product) => !isBoa(product?.handle))
      : []
    if (filterBoa) {
      setProducts(result || [])
    } else {
      setProducts(filteredProducts)
    }
  }, [locale])

  useEffect(() => {
    couponCallback()
  }, [couponCallback])

  return products
}

export default useAllDiscountedProducts
