import { Picture, Text } from '@components/ui'
import Marquee from 'react-fast-marquee'
import HomeTitle from '@components/home/HomeTitle/HomeTitle'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

const Index = ({ data, pageHandle, id, isMobile, playAni }) => {
  return (
    <section className="layer">
      <div className="content leading-[1.4] text-[#1D1D1F]">
        <HomeTitle
          data={data}
          pageHandle={pageHandle}
          id={id}
          playAni={playAni}
        ></HomeTitle>
        <div className="border-b border-[#ddd] pb-[24px] md:pb-[12px]">
          <Marquee
            speed={isMobile ? 25 : 30}
            direction={'right'}
            gradientWidth={0}
            style={{
              display: 'flex',
            }}
            onFinish={() => ScrollTrigger?.refresh()}
          >
            {data?.list?.map((item, index) => (
              <div key={index} className="mr-[60px] md:!mr-[24px] xl:mr-[48px]">
                <Text
                  className="text-[32px] font-[600] l:!text-[20px] xl:text-[26px]"
                  html={item?.tit}
                />
                <Text
                  className="mt-[4px] text-[16px] font-[500] text-[#777] l:!text-[12px] xl:text-[14px]"
                  html={item?.desc}
                />
                {item?.icon && (
                  <Picture
                    className="w-[120px] xl:w-[72px]"
                    source={item?.icon}
                  />
                )}
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  )
}

export default Index
