import { Picture, Text, ButtonV2 } from '@components/ui'
import { useEffect } from 'react'
import { easingTransitions } from '@lib/utils/animate'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { motion } from 'framer-motion'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useTimer } from 'react-timer-hook'

import cn from 'classnames'

const Index = ({ data, pageHandle, id, gtmRef }) => {
  const { setRelativeLink } = useRelativeLink()
  const isDark = data?.theme === 'dark'

  useEffect(() => {
    if (!data?.playAni) return
    const width = document.querySelector('body').offsetWidth
    gsap.registerPlugin(ScrollTrigger)
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${id}`,
          start: 'top top',
          end: 'bottom top-=20%',
          // markers: true,
          scrub: 0,
          ease: 'linear',
        },
      })
      .to(`#${id} .aniImage`, { y: width > 768 ? '200px' : '100px' })
  }, [])

  return (
    <div className="relative w-full">
      <motion.div
        className="scale(1.3) w-full"
        initial={{ transform: 'scale(1.3)' }}
        animate={{ transform: 'scale(1)' }}
        transition={{
          duration: 1.2,
          ease: easingTransitions?.easeInOutQuart,
        }}
      >
        <Picture
          source={`${data?.bgUrl}, ${data?.bgMobileUrl || data?.bgUrl} 1023`}
          className="aniImage aspect-h-[970] aspect-w-[1920] w-full md:!aspect-h-[640] md:!aspect-w-[390] l:aspect-h-[914] l:aspect-w-[768]"
          imgClassName="w-full h-full object-cover"
          loading="eager"
        ></Picture>
      </motion.div>
      <div
        className={cn(
          'layer_max absolute top-1/2 -translate-y-1/2 l:top-[10%] l:-translate-y-0'
        )}
      >
        <div
          className={cn(
            'content l:text-center',
            isDark ? 'text-[#fff]' : 'text-[#1D1D1F]'
          )}
        >
          <div className="w-full max-w-[700px] l:!max-w-none xl:!max-w-[400px] xxl:max-w-[632px]">
            <div>
              {data?.tag && (
                <div className="mb-[4px] text-[20px] font-[500] leading-[1.4] text-[#FF9900] xl:text-[14px]">
                  <BannerTxt txt={data?.tag} />
                </div>
              )}
              {data?.preTitle && (
                <div className="mb-[4px] text-[20px] font-[500] leading-[1.4] xl:text-[14px]">
                  <BannerTxt txt={data?.preTitle} />
                </div>
              )}
              {data?.title && (
                <div className="text-[54px] font-[700] leading-[1.2] xl:!text-[32px] xxl:text-[46px]">
                  <BannerTxt txt={data?.title} />
                </div>
              )}
              {data?.desc && (
                <div className="mt-[4px] text-[20px] font-[500] leading-[1.4] xl:text-[16px]">
                  <BannerTxt txt={data?.desc} />
                </div>
              )}
            </div>
            {(data?.countDownData || data?.buttons) && (
              <motion.div
                className="mt-[64px] opacity-0 md:!mt-[24px] xl:mt-[48px]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.9,
                  duration: 0.3,
                  ease: easingTransitions?.easeInOutQuart,
                }}
              >
                {data?.countDownData?.endTime && (
                  <div className="mb-[32px] xl:mb-[16px]">
                    <CountDown
                      isDark={isDark}
                      onlyNumber={data?.countDownData?.onlyNumber || false}
                      countDown={data?.countDownData}
                    />
                  </div>
                )}
                {data?.buttons && (
                  <div className="flex gap-[12px] l:justify-center">
                    {data?.buttons?.map((item, index) => {
                      const link = appendRefParameter({
                        url: setRelativeLink({ link: item?.link }),
                        refValue: gtmRef ? gtmRef : pageHandle,
                      })
                      return (
                        <ButtonV2 key={index} variant={item?.theme}>
                          <a
                            href={link}
                            dangerouslySetInnerHTML={{ __html: item?.label }}
                          />
                        </ButtonV2>
                      )
                    })}
                  </div>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index

const BannerTxt = ({ txt }) => {
  return (
    <motion.span
      className="inline-block md:block"
      style={{
        clipPath: 'rect(0 0 500% 0)',
      }}
      initial={{ clipPath: 'rect(0 0 500% 0)' }}
      animate={{ clipPath: 'rect(0 150% 500% 0)' }}
      transition={{
        delay: 0.4,
        duration: 0.6,
        ease: easingTransitions?.easeInOutQuart,
      }}
      dangerouslySetInnerHTML={{ __html: txt }}
    />
  )
}

const CountDown = ({ countDown, onlyNumber, isDark }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(countDown?.endTime),
  })
  if (new Date() > new Date(countDown?.endTime)) return null
  if (Number(days) >= (Number(countDown?.maxDays) || 7)) return null
  return (
    <div className="CountDown flex items-center gap-[10px] l:justify-center">
      <Text
        html={countDown.prefix}
        className="CountDown-prefix text-[20px] font-[500] md:text-[14px]"
        variant="paragraph"
        style={{
          color: `${isDark ? '#fff' : '#1D1D1F'}`,
        }}
      />
      {onlyNumber ? (
        <div className="CountDown-number flex">
          <Text
            html={countDown?.countDownFormat
              ?.replace('$days', String(days).padStart(2, '0'))
              ?.replace('$hours', String(hours).padStart(2, '0'))
              ?.replace('$minutes', String(minutes).padStart(2, '0'))
              ?.replace('$seconds', String(seconds).padStart(2, '0'))
              ?.replace(
                '$totalHours',
                String(24 * days + hours).padStart(2, '0')
              )}
            variant="paragraph"
            className={cn(
              'mb-2 mt-2 flex-1 text-[16px] font-semibold md:text-[14px]'
            )}
            style={{
              color: `${isDark ? '#fff' : '#1D1D1F'}`,
            }}
          />
        </div>
      ) : (
        <div className="CountDown-box flex items-center">
          {days > 0 && <Item time={days} isDark={isDark} />}
          <Item time={hours} isDark={isDark} />
          <Item time={minutes} isDark={isDark} />
          <Item time={seconds} last={true} isDark={isDark} />
        </div>
      )}
    </div>
  )
}

const n = (n) => (n > 9 ? '' + n : '0' + n)

const Item = ({ time, last, isDark }) => (
  <div className="flex items-center">
    <div className="flex flex-col items-center">
      <div
        className="relative flex h-[42px] w-[42px] items-center justify-center overflow-hidden pt-[4px] text-[24px] font-[700] md:h-[30px] md:w-[30px] md:pt-[2px] md:text-[14px]"
        style={{
          background: `${isDark ? '#fff' : '#1D1D1F'}`,
          color: `${isDark ? '#1D1D1F' : '#fff'}`,
        }}
      >
        {n(time)}
      </div>
    </div>
    {!last && (
      <span
        className={cn('mx-[4px] text-[24px] font-[600] md:text-[14px]')}
        style={{
          color: `${isDark ? '#fff' : '#1D1D1F'}`,
        }}
      >
        :
      </span>
    )}
  </div>
)
